import React from "react";
import { Link } from "react-router-dom";
import Hero from "../assets/images/Hero.png";
import Icon from "../assets/images/reservaticon.png";

export interface Props {
  products: {
    id: number;
    name: string;
    autor: string;
    description: string;
    category: string;
    href: string;
    imageAlt: string;
  }[];
}

const Product = (props: Props) => {
  const { products } = props;
  return (
    <div className="bg-gray-50 ">
      <div className="max-w-2xl mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between space-x-4">
          <h2 className="text-lg font-medium text-gray-900">Top Apis</h2>

          {/* <nav className="whitespace-nowrap text-sm font-medium text-orange-600 hover:text-orange-500">
            <Link to="/">
              Ver todas<span aria-hidden="true"> &rarr;</span>
            </Link>
          </nav> */}
        </div>
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
          {products.map((product) => (
            <div
              key={product.id}
              className="relative group border-2 p-4 rounded-lg"
            >
              <div className=" aspect-w-3 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                <img
                  src={Hero}
                  alt={product.imageAlt}
                  className="object-center object-cover"
                />
                <div
                  className="flex items-end opacity-0 p-4 group-hover:opacity-100"
                  aria-hidden="true"
                >
                  <div className="w-52 mx-auto bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                    Ver mas..
                  </div>
                </div>
              </div>
              <div className="mt-2 flex items-center justify-between text-base font-medium text-gray-900 space-x-8">
                <h3>
                  <nav>
                    <Link to={product.href}>
                      <img
                        className="relative bottom-8 left-8 w-16 h-16  "
                        src={Icon}
                        alt=""
                      />
                      <span aria-hidden="true" className="absolute inset-0  " />
                      <div className=" text-left w-full mb-">
                        <h3 className=" text-2xl ">{product.name}</h3>
                        <p>{product.autor}</p>
                      </div>
                    </Link>
                  </nav>
                </h3>
              </div>
              <p> {product.description} </p>
              <p className="mt-1 text-sm text-gray-500">{product.category}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
