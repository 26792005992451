import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Reservat",
  version: { name: "1.0", date: "Junio 5, 2021", datetime: "2021-06-05" },
  description:
    "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  highlights: ["Endpoint 1", "Endpoint 1", "Endpoint 1"],
};

const faqs = [
  {
    question: "Q1?",
    answer:
      "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  },
  // More FAQs...
];

const license = {
  href: "#",
  summary: "MIT",
  content: `

    <p>Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.</p>
  `,
};

const ApiDetailsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} license={license} />
      <Footer />
    </div>
  );
};

export default ApiDetailsPage;
