import React from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/App.css";
import {
  HomePage,
  ApiDetailsPage,
  MarketPage,
  ReservatPage,
  CredentialsDetailsPage,
  AccountDetailsPage,
} from "./pages";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/details" element={<ApiDetailsPage />} />
        <Route path="/reservat" element={<ReservatPage />} />
        <Route path="/credentials" element={<CredentialsDetailsPage />} />
        <Route path="/accounts" element={<AccountDetailsPage />} />
        <Route path="/market" element={<MarketPage />} />
      </Routes>
    </div>
  );
}

export default App;
