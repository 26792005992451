import React from "react";
import { Footer, Header, Hero } from "../components";

const HomePage = () => {
  return (
    <div className=" bg-gray-50 ">
      <Header />
      <Hero />
      <Footer />
    </div>
  );
};

export default HomePage;
