import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../assets/images/LogoEntio.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="z-40 sticky top-0 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-80 border border-gray-200">
      <Popover className="relative max-w-7xl mx-auto">
        <div className=" flex justify-between items-center px-4 py-4 sm:px-6 md:justify-start md:space-x-10">
          <div>
            <nav className="flex">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
              </Link>
            </nav>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <nav className="text-base font-medium text-gray-500 hover:text-gray-900">
                <Link to="/">Home</Link>
              </nav>
              <nav className="text-base font-medium text-gray-500 hover:text-gray-900">
                <Link to="/market">Marketplace</Link>
              </nav>
            </Popover.Group>
            {/* <div className="flex items-center md:ml-12">
              <nav className="text-base font-medium text-orange-500 hover:text-orange-900">
                <Link to="/">Login</Link>
              </nav>
            </div> */}
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="Workflow" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <nav className="text-base font-medium text-gray-900 hover:text-gray-700">
                    <Link to="/">Home</Link>
                  </nav>
                  <nav className="text-base font-medium text-gray-900 hover:text-gray-700">
                    <Link to="/market">Marketplace</Link>
                  </nav>
                </div>
                {/* <div className="mt-6">
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    ¿Ya tienes cuenta?{" "}
                    <nav className="text-orange-600 hover:text-orange-900">
                      <Link to="/">Login</Link>
                    </nav>
                  </p>
                </div> */}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default Header;
