import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Credentials",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  highlights: ["Endpoint 1", "Endpoint 2", "Endpoint 3"],
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const license = {
  href: "#",
  summary: "MIT",
  content: `

    <p>Descripcion de la licencia de uso</p>
  `,
};

const ApiDetailsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} license={license} />
      <Footer />
    </div>
  );
};

export default ApiDetailsPage;
