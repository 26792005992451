import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Accounts",
  version: { name: "1.0", date: "Septiembre 5, 2022", datetime: "2022-09-05" },
  description:
    "Entio Accounts es su solución para administrar usuarios de forma segura, rápida y sencillamente.",
  // highlights: ["Endpoint 1", "Endpoint 2", "Endpoint 3"],
  links: [
      {
        name: 'SDK README',
        url: 'https://bitbucket.org/entio_devs/entio-lib/src/development/lib/entio-sdk/entio-accounts-sdk/README.md',
      },
      {
        name: 'SDK JSDoc',
        url: 'https://bitbucket.org/entio_devs/entio-lib/src/development/lib/entio-sdk/entio-accounts-sdk/docs/modules.md',
      },
      {
        name: 'API Docs',
        url: 'https://documenter.getpostman.com/view/22600051/VV4tUJLy',
      },
      {
        name: '⬇️Download Postman Collection',
        url: '/postman/entio-accounts/Entio Accounts.postman_collection.json',
        filename: 'Entio Accounts.postman_collection.json'
      },
      {
        name: '⬇️Download Postman DEV Environment',
          url: '/postman/entio-accounts/Entio Accounts - DEV.postman_environment.json',
          filename: 'Entio Accounts - DEV.postman_environment.json'
      },
  ]
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const license = {
  href: "#",
  summary: "MIT",
  content: `

    <p>Descripcion de la licencia de uso</p>
  `,
};

const ApiDetailsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} license={license} />
      <Footer />
    </div>
  );
};

export default ApiDetailsPage;
